import React from 'react';
import './footerStyles.scss';

const Footer = (props) => {
  const year = new Date().getFullYear();

  return (
    <footer className='footer'>
      <p>
        Copyright {year} Living Validated LLC. •{' '}
        <a
          href='livingvalidated.com
'
          target='_blank'
          rel='noreferrer noopener'
        >
          built by Neongold Studio
        </a>
      </p>
    </footer>
  );
};

export default Footer;
