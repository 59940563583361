import React from "react";
import "./aboutContainerStyles.scss";
import SectionLayout from "../../ui/SectionLayout/SectionLayout";
import aboutContentImage1 from "../../../assets/about-content-img-1.jpg";
import aboutContentImage2 from "../../../assets/about-content-img-2.jpg";
import FeedbackCard from "../FeedbackCard/FeedbackCard";
import ndvHotline from "../../../assets/logo-ndvh.svg";
import dsLogo from "../../../assets/ds_logo.jpg";
import dualSupportLogo from "../../../assets/dual_support_logo.png";
import coachCert from "../../../assets/coach-cert.png";

const AboutContainer = (props) => {
  return (
    <SectionLayout customClass="about__container">
      <h2 className="about__section-title">About</h2>
      <div className="about__content-section">
        <div className="about__content-text-container">
          <h3 className="about__content-subtitle">
            You are Stronger Than You Think
          </h3>
          <p className="about__content-text">
            As a survivor of abuse, you may often feel embarrassed, defeated,
            humiliated, or feel a sense of loss, hopelessness, and all alone.
          </p>
          <p className="about__content-text">
            Most survivors of abusive relationships suffer in silence thinking
            “No one will believe me” or “No one will even care”. I once was in
            your shoes. I have walked the road that you are about to embark. My
            story is much like your own. I was physically beaten, sexually
            assaulted, emotionally, verbally abused, and financially broken. I
            took my power back and transformed life. What was meant to destroy
            ME, I used to fuel ME. It is my honor to provide you with
            compassionate expertise.
          </p>
        </div>
        <div className="about__image-container">
          <img
            src={aboutContentImage1}
            alt="Young students outside of school"
            className="about__content-image"
          />
        </div>
      </div>
      <div className="about__quote-container">
        <blockquote className="about__quote">
          “Everyone has the human right to live in safety and free from violence
          and abuse. Society has a duty to recognize and defend this right.”
        </blockquote>
        {/* <h4 className='about__quote-author'>— A trusted source</h4> */}
      </div>
      <div className="about__content-section">
        <div className="about__image-container">
          <img
            src={aboutContentImage2}
            alt="Bridgette Alexander CEO Headshot"
            className="about__content-image"
          />
        </div>

        <div className="about__content-text-container">
          <h3 className="about__content-subtitle">
            <span className="g__text-emphasis">Bridgette Alexander</span> CEO,
            Living Validated
          </h3>
          <p className="about__content-text">
            Hello, I am Bridgette and it's nice to meet you!
          </p>
          <p className="about__content-text">
            I am a survivor of domestic violence, healed and thriving with 30
            years of experience working in various positions in the field of
            domestic violence. My purpose and passion are assisting survivors of
            abuse to take the next steps to healing their trauma.
          </p>
          <p className="about__content-text">
            I am the CEO of “Living Validated, LLC” As a
            inspirational/motivational speaker and domestic violence educator, I
            hold a Master degree in Psychology minoring in Life Coaching. At
            Living Validated, Life Coaching techniques are employed assisting
            survivors of abuse to live “On purpose, For purpose!”
          </p>
          <p className="about__content-text">
            On a personal level, I am the mother of 3 children L’Oreal,
            Johnathan, and Edwina. Three grandchildren- Isaiah, Brycen, and
            Xavier. When I am not helping others, I am giving to myself. I enjoy
            the freedom of being unapologetically “Me.” and doing the – ing;
            breathing, exhaling, relaxing, and recharging. I have learned to
            fill my cup first, then pour into others. One can’t pour out of an
            empty well and expect change to occur.
          </p>
          <p className="about__content-text">
            My personal philosophy have delevoped from my life experiences. ‍
            They are: ‍ 1) “You Have to Understand Where You Have Been to Get to
            Where You Are Going.” ‍ 3) “Those imperfections that others are
            quick to point out – Embrace them as something that make YOU
            uniquely you” ‍
          </p>

          <img
            className="about__cert-icon"
            src={coachCert}
            alt="Coaching Certification"
          />
        </div>
      </div>
      <div className="about__milestone-container">
        <h3 className="about__content-subtitle">
          Thoughts from Survivors learning to live in peace!
        </h3>
        {/* <p className='about__content-text'></p> */}
        <div className="about__milestone-cards">
          <FeedbackCard
            title="Survivor"
            message="I appreciate the opportunity to learn from someone who has had experiences like mine. Thank you for not judging or rushing me but encouraging me to find my own way."
          />
          <FeedbackCard
            title="Survivor"
            message="It was so difficult for me to accept that I was a victim of financial, emotional, legal, and psychological abuse. My abuser was getting progressively more aggressive and knew I had to set boundaries in order to survive. Ms. Bridgette helped me to separate my day to day life from my abuser, use my voice, and let go of fear. Thank you for the validation and tools to live my life fulfilled."
          />
          <FeedbackCard
            title="Survivor"
            message="Ms. Bridgette is an incredible coach and mentor for another that is looking to heal from an abusive relationship. She has taught me to understand the dynamics of power and control. Her weekly empowerment classes have allowed me to apply what I've learned to my daily life. She does not want anyone to become dependent on her because she truly believes in teaching survivors to regain their voices and reclaim their strengths. She is a person of high integrity and compassion. Her wisdom is priceless."
          />
        </div>
      </div>
      <div className="about__quote-container">
        <h2 className="about__quote">
          You are <span className="g__text-emphasis"> not </span>
          alone.
        </h2>
      </div>
      <div className="about__partner-section">
        <h3 className="about__content-subtitle">Advocates I Support</h3>
        <div className="about__partners">
          <div className="about__partner-container">
            <a
              href="https://www.thehotline.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ndvHotline}
                alt="some partner logo"
                className="about__partner-img"
              />
            </a>
            <p className="about__partner-text">
              National Domestic Violence Hotline
            </p>
          </div>
          <div className="about__partner-container">
            <a
              href="https://laparalegalsllc.com/dual-support"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={dualSupportLogo}
                alt="dual-support-logo"
                className="about__partner-img"
              />
            </a>
            <p className="about__partner-text">DUAL Support</p>
          </div>
          <div className="about__partner-container">
            <a
              href="https://www.domesticshelters.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={dsLogo}
                alt="dual-support-logo"
                className="about__partner-img"
              />
            </a>
            <p className="about__partner-text">DomesticShelters.org</p>
          </div>
        </div>
      </div>
    </SectionLayout>
  );
};

export default AboutContainer;
