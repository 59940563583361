import React from 'react';
import './contactContainerStyles.scss';
import logo from '../../../assets/lv-logo-shadow.png';
import SectionLayout from '../../ui/SectionLayout/SectionLayout';
import SocialMediaIcon from '../../ui/SocialMediaIcon/SocialMediaIcon';
import ContactForm from '../ContactForm/ContactForm';

const ContactContainer = (props) => {
  return (
    <SectionLayout customClass='contact__container' id='contact'>
      <img src={logo} alt='Living Validated Logo' className='contact__logo' />
      <div className='contact__connect-container'>
        <h2 className='contact__title contact__title--left'>
          Connect <span className='g__text-emphasis'>with me</span>
        </h2>
        <div className='contact__contact-group'>
          <h3>phone</h3>
          <a href='tel:5042332130'>(504) 233-2130</a>
        </div>
        <div className='contact__contact-group'>
          <h3>email</h3>
          <a href='mailto:lv@livingvalidated.com'>lv@livingvalidated.com</a>
        </div>
        <div className='contact__contact-group'>
          <h3>follow on social</h3>
          <SocialMediaIcon
            type={'youtube'}
            location={'contact'}
            theme={'light'}
            disable={false}
          />
          <SocialMediaIcon
            type={'facebook'}
            location={'contact'}
            theme={'light'}
            disable={false}
          />
          <SocialMediaIcon
            type={'instagram'}
            location={'contact'}
            theme={'light'}
            disable={false}
          />
          <SocialMediaIcon
            type={'tiktok'}
            location={'contact'}
            theme={'light'}
            disable={false}
          />
          {/*<SocialMediaIcon*/}
          {/*    type={"linkedin"}*/}
          {/*    location={"contact"}*/}
          {/*    theme={"light"}*/}
          {/*    disable={false}*/}
          {/*/>*/}
          {/*<SocialMediaIcon*/}
          {/*    type={"twitter"}*/}
          {/*    location={"contact"}*/}
          {/*    theme={"light"}*/}
          {/*    disable={false}*/}
          {/*/>*/}
        </div>
      </div>
      <div className='contact__form-container'>
        <h2 className='contact__title contact__title--right'>
          Contact<span className='g__text-emphasis'>me</span>
        </h2>
        <ContactForm />
      </div>
    </SectionLayout>
  );
};

export default ContactContainer;
