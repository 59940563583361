import React, { useEffect, useState } from 'react';
import './resourcesContainerStyle.scss';
import SectionLayout from '../../ui/SectionLayout/SectionLayout';
import resourceImg1 from '../../../assets/resources-images/subtle-dating-signs.png';
import resourceImg2 from '../../../assets/resources-images/save-a-life.png';
import resourceImg3 from '../../../assets/resources-images/women-uplift.png';
import resourceImg4 from '../../../assets/resources-images/out-here-in-these-streets.png';
import resourceImg5 from '../../../assets/resources-images/power-control-wheel.jpg';
import resourceImg6 from '../../../assets/resources-images/deepthought.png';
import ResourcesTab from '../ResourcesTab/ResourcesTab';
import TabImage from '../TabImage/TabImage';
import useWindowDimensions from '../../../utils/useWindowDimensions';

const ResourcesContainer = (props) => {
  const { width } = useWindowDimensions();
  const [offsetY, setOffsetY] = useState(0);
  const [currentTab, setCurrentTab] = useState('tab1');
  const [desktopView, setDesktopView] = useState(true);

  useEffect(() => {
    if (width > 676) {
      setDesktopView(true);
    } else {
      setDesktopView(false);
    }
  }, [width]);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const tabContent = [
    {
      name: 'tab1',
      title: 'Subtle Dating Signals Part 2',
      description:
        'Coffee and Conversation talk about subtle dating signals. Are you guilty of ghosting, love bombing, or gaslighting? Or have you been the unsuspecting recipient?  Dating can be fun, and sometimes it can get really real.',
      ctaLink: 'https://www.youtube.com/live/JJGLB_KBbCI?feature=shared',
      ctaText: 'watch video',
      image: resourceImg1,
    },
    {
      name: 'tab2',
      title: 'Save a Life',
      description: 'Coffee and Conversations about...',
      ctaLink: 'https://youtu.be/hOt-LLQRwk4?feature=shared',
      ctaText: 'watch video',
      image: resourceImg2,
    },
    {
      name: 'tab3',
      title: 'Life Coaching vs Therapy vs Counseling',
      description:
        'How life coaching, therapy, and counseling can work together to assist survivors in living a fulfilling life.',
      ctaLink: 'https://medium.com/',
      ctaText: 'view post',
      image: resourceImg3,
    },
    {
      name: 'tab4',
      title: 'Out Here In These Dating Streets!',
      description:
        'Coffee & Conversation where we discussed important dating topics like Cookie Jarring, Anchoring, House Planting, and Paper Clipping be informed and taking the stress out of dating.',
      ctaLink: 'https://www.youtube.com/live/otN1U9nsPUU?feature=shared',
      ctaText: 'watch video',
      image: resourceImg4,
    },
    {
      name: 'tab5',
      title: 'Understanding the Power and Control Wheel',
      description:
        'The Duluth Model. Batterer Intervention Specialist explains the Power and Control Wheel and the different tactics batterers use.',
      ctaLink: 'https://youtu.be/5OrAdC6ySiY?feature=shared',
      ctaText: 'watch video',
      image: resourceImg5,
    },
    {
      name: 'tab6',
      title: "Do You Think You're in an Abusive Relationship?",
      description:
        'Recognize the red flags of abusive relationship to regain your power.',
      ctaLink: 'https://medium.com/',
      ctaText: 'view post',
      image: resourceImg6,
    },
  ];

  const tabHandler = (tabName) => {
    setCurrentTab(tabName);
  };

  return (
    <SectionLayout id='resources' customClass='resources__container'>
      <div
        style={{ transform: desktopView && `translateY(${offsetY * 0.02}px)` }}
        className='resources__image-container'
      >
        {tabContent.map((img, i) =>
          img.name === currentTab ? (
            <TabImage
              key={img.name + i * 3}
              active={currentTab}
              image={img.image}
            />
          ) : null
        )}
      </div>
      <div
        className='resources__content-column'
        style={{ transform: desktopView && `translateY(${offsetY * -0.01}px)` }}
      >
        <h2 className='resources__headline'>
          Community <span className='g__text-emphasis'>resources </span>
        </h2>
        <ul className='resources__tab-container'>
          {tabContent.map((tab, i) => {
            return (
              <ResourcesTab
                key={tab.name + i * 2}
                tabHandler={() => tabHandler(tab.name)}
                active={currentTab}
                name={tab.name}
                title={tab.title}
                description={tab.description}
                ctaText={tab.ctaText}
                ctaLink={tab.ctaLink}
              />
            );
          })}
        </ul>
      </div>
    </SectionLayout>
  );
};

export default ResourcesContainer;
