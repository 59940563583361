import React from "react";
import Navbar from "./components/header/Navbar/Navbar";
import Modal from "./components/ui/Modal/Modal";
import HeroContainer from "./components/header/HeroContainer/HeroContainer";
import { LVProvider } from "./utils/ContextProvider";
// import MailchimpForm from './components/header/MailchimpForm/MailchimpForm';
import PinnedSideBar from "./components/PinnedSideBar/PinnedSideBar";
import MobileBackToTop from "./components/MobileBackToTop/MobileBackToTop";
import ResourcesContainer from "./components/resources/ResourcesContainer/ResourcesContainer";
import AboutHeroBanner from "./components/about/AboutHeroBanner/AboutHeroBanner";
import AboutContainer from "./components/about/AboutContainer/AboutContainer";
// import VideosContainer from './components/videos/VideosContainer/VideosContainer';
import ContactContainer from "./components/contact/ContactContainer/ContactContainer";
import Footer from "./components/Footer/Footer";

import ReactGA from "react-ga";
ReactGA.initialize("G-986LL3R354");
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
  return (
    <>
      <LVProvider>
        <PinnedSideBar />
        <MobileBackToTop />
        <header id="top">
          <Navbar />
          <HeroContainer />
        </header>
        <main>
          <AboutHeroBanner />
          <AboutContainer />
          <ResourcesContainer />
          {/* <VideosContainer /> */}
          <ContactContainer />
        </main>
        <Footer />
        <Modal>{/* <MailchimpForm /> */}</Modal>
      </LVProvider>
    </>
  );
};

export default App;
