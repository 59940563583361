import React, { useState } from "react";
import "./contactFormStyles.scss";
import InputField from "../../ui/InputField/InputField";

const ContactForm = (props) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);

  console.log(setFormIsValid);

  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() =>
        console.log(
          "Form successfully submitted",
          email,
          name,
          phone,
          message,
          formIsValid
        )
      )
      .catch((error) => alert(error));
  };

  document.querySelector("form").addEventListener("submit", handleSubmit);

  return (
    <form
      className="mc__form-container"
      method="post"
      name="contact"
      data-netlify-recaptcha="true"
      data-netlify="true"
      onSubmit={handleSubmit}
    >
      <input
        type="hidden"
        name="form-name"
        value="contact"
      />
      <InputField
        label="Name"
        onChangeHandler={setName}
        type="text"
        value={name}
        placeholder="Fullname or Alias"
        isRequired
        name="name"
      />

      <InputField
        label="Phone"
        onChangeHandler={setPhone}
        type="phone"
        value={phone}
        placeholder="555-555-5555"
        isRequired
        name="phone"
      />

      <InputField
        label="Email"
        onChangeHandler={setEmail}
        type="email"
        value={email}
        placeholder="your@email.com"
        isRequired
        name="email"
      />

      <InputField
        label="Message"
        onChangeHandler={setMessage}
        type="textarea"
        value={message}
        placeholder="How can we help..."
        isRequired
        name="message"
      />
      {/*Always put the email in first in this array for validation reasons*/}
      <InputField
        label="send"
        type="submit"
        formValues={[email, name, phone, message]}
      />
    </form>
  );
};

export default ContactForm;
