import React from "react";
import "./heroStyles.scss";
import SectionLayout from "../../ui/SectionLayout/SectionLayout";
import MobileSocialMediaBar from "../MobileSocialMediaBar/MobileSocialMediaBar";

const HeroContainer = (props) => {
  return (
    <>
      <SectionLayout customClass="hero__container">
        <div className="hero__content">
          <h1>
            On
            <span className="g__text-emphasis"> purpose</span>
          </h1>
          <h1>
            For
            <span className="g__text-emphasis"> purpose</span>
          </h1>
          <p className="hero__subhead">
            To live validated is to live life Empowered with 'Authority" knowing
            the power of your own Self-Worth. From here forward, Living
            Validated™ is available to support you as you navigate your path to
            freedom "validated" in knowing that you deserve the wellness and
            peace you seek.{" "}
          </p>
          <MobileSocialMediaBar />
        </div>
      </SectionLayout>

      <div className="hero__img-container">
        <div className="hero__bg-img hero__img-1" />
        <div className="hero__bg-img hero__img-2" />
        <div className="hero__bg-img hero__img-1" />
        <div className="hero__bg-img hero__img-2" />
        <div className="hero__bg-img hero__img-1" />
      </div>
    </>
  );
};

export default HeroContainer;
