import React, { useContext, useState } from 'react';

const LVContext = React.createContext();

export const useLVtContext = () => {
  return useContext(LVContext);
};

export function LVProvider({ children }) {
  // State that controls if modal should open/close
  const [modalOpen, setModalOpen] = useState(false);
  const [safetyExitClicked, setSafetyExitClicked] = useState(false);

  return (
    <LVContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        safetyExitClicked,
        setSafetyExitClicked,
      }}
    >
      {children}
    </LVContext.Provider>
  );
}
