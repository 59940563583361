import React, { useState } from 'react';
import './smIconStyles.scss';
import facebookDark from '../../../assets/social-icons/facebook-dark.svg';
import facebookLight from '../../../assets/social-icons/facebook-light.svg';
import youtubeDark from '../../../assets/social-icons/youtube-dark.svg';
import youtubeLight from '../../../assets/social-icons/youtube-light.svg';
import tiktokDark from '../../../assets/social-icons/tiktok-dark.svg';
import tiktokLight from '../../../assets/social-icons/tiktok-light.svg';
import instagramDark from '../../../assets/social-icons/instagram-dark.svg';
import instagramLight from '../../../assets/social-icons/instagram-light.svg';
// import linkedinDark from '../../../assets/social-icons/linkedin-dark.svg';
// import linkedinLight from '../../../assets/social-icons/linkedin-light.svg';
// import twitterDark from '../../../assets/social-icons/twitter-dark.svg';
// import twitterLight from '../../../assets/social-icons/twitter-light.svg';

const SocialMediaIcon = (props) => {
  const [iconHover, setIconHover] = useState();

  let socialMediaData = {
    facebook: {
      theme: {
        dark: facebookDark,
        light: facebookLight,
      },
      url: 'https://www.facebook.com/profile.php?id=61550999914262',
    },
    youtube: {
      theme: {
        dark: youtubeDark,
        light: youtubeLight,
      },
      url: 'https://www.youtube.com/@LivingValidated',
    },
    instagram: {
      theme: {
        dark: instagramDark,
        light: instagramLight,
      },
      url: 'https://instagram.com/living.validated?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==',
    },
    tiktok: {
      theme: {
        dark: tiktokDark,
        light: tiktokLight,
      },
      url: 'https://www.tiktok.com/@living.validated',
    },
    // linkedin: {
    //   theme: {
    //     dark: linkedinDark,
    //     light: linkedinLight,
    //   },
    //   url: '',
    // },
    // twitter: {
    //   theme: {
    //     dark: twitterDark,
    //     light: twitterLight,
    //   },
    //   url: '',
    // },
  };

  //Holds social media account link
  let socialUrl = socialMediaData[props.type].url;

  //Holds the icon src
  let iconType = socialMediaData[props.type].theme[props.theme];

  //Holds the icon src for the hover state
  let iconTypeActive =
    socialMediaData[props.type].theme[
      props.theme === 'dark' ? 'light' : 'dark'
    ];

  //Determines the correct icon style
  let linkStyle = ['social-link'];

  if (props.disable) {
    linkStyle.push('social-link--disabled');
  } else if (props.location === 'sidebar') {
    linkStyle.push('social-link--sidebar');
  } else if (props.location === 'mobile-hero') {
    linkStyle.push('social-link--mobile-hero');
  } else if (props.location === 'mobile-nav') {
    linkStyle.push('social-link--mobile-nav');
  } else if (props.location === 'contact') {
    linkStyle.push('social-link--contact');
  }

  return (
    <a
      className={linkStyle.join(' ')}
      href={socialUrl}
      aria-disabled={props.disable}
      rel='noreferrer noopener'
      target='_blank'
      onMouseEnter={() => setIconHover(true)}
      onMouseLeave={() => setIconHover(false)}
    >
      <img
        src={iconHover ? iconTypeActive : iconType}
        alt={` ${props.type} icon`}
      />
      {props.location === 'contact' ? <p>{props.type}</p> : null}
    </a>
  );
};

export default React.memo(SocialMediaIcon);
