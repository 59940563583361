import React from "react";
import "./feedbackCardStyles.scss";
import butterfly from "../../../assets/butterfly.png";

const FeedbackCard = (props) => {
  return (
    <div className="feedback___card">
      <img
        src={butterfly}
        alt="butterfly"
        className="butterfly_icon"
        style={{ width: 180, height: 180 }}
      />

      <h2 className="feedback__title">{props.title}</h2>
      <h4 className="message">{props.message}</h4>
    </div>
  );
};

export default FeedbackCard;
